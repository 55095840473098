<template>
  <b-card>
    <b-row class="mx-0">
      <prozess-search-input
        v-model="query"
        :placeholder="$t('Search')"
      />
      <b-button
        class="ml-1"
        @click="openModal()"
      >
        {{ $t('Add Sale') }}
      </b-button>
      <b-button class="ml-1">
        {{ $t('History') }}
      </b-button>
    </b-row>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      variant="transparent"
      style="flex: 1"
      class="d-flex flex-column mt-2"
    >
      <b-table
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
      >
        <template #head()="{ label }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ $t(label) }}</span>
          </div>
        </template>
        <template #cell(product)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ getProductLabel(item.values.product) }}</span>
          </div>
        </template>
        <template #cell(car_brand)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ item.values.car_brand }}</span>
          </div>
        </template>
        <template #cell(car_model)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ item.values.car_model }}</span>
          </div>
        </template>
        <template #cell(chassis_number)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ item.values.chassis_number }}</span>
          </div>
        </template>
        <template #cell(new_car)="{ item }">
          <div class="w-100 text-center">
            <feather-icon
              v-if="item.values.new_car"
              icon="CheckCircleIcon"
              size="16"
            />
            <feather-icon
              v-else
              icon="XCircleIcon"
              size="16"
            />
          </div>
        </template>
        <template #cell(week)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ item.values.week }}</span>
          </div>
        </template>
        <template #cell(date)="{ item }">
          <div class="w-100 text-center">
            <span class="text-nowrap">{{ item.values.date | moment }}</span>
          </div>
        </template>
        <!-- START: Actions Column -->
        <template #cell(manage)="{ item }">
          <div class="d-flex align-items-center justify-content-center">
            <!-- Edit Button -->
            <feather-icon
              :id="`${item.values.uuid_key}-edit`"
              class="mx-1 cursor-pointer"
              icon="EditIcon"
              size="16"
              @click="openModal(item.values)"
            />
            <b-tooltip
              :target="`${item.values.uuid_key}-edit`"
              :title="$t('Edit')"
              placement="top"
            />
            <!-- Delete Button -->
            <feather-icon
              :id="`${item.values.uuid_key}-delete`"
              size="16"
              icon="TrashIcon"
              class="mx-1 cursor-pointer"
              @click="remove(item.values)"
            />
            <b-tooltip
              :target="`${item.values.uuid_key}-delete`"
              :title="$t('Delete')"
              placement="top"
            />
          </div>
        </template>
        <!-- END: Action Column -->
      </b-table>
      <b-pagination
        v-model="page"
        :total-rows="allItemsCount"
        :per-page="size"
        first-number
        align="end"
        last-number
        class="mt-1 mt-sm-0 align-self-end"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="20"
          />
        </template>
      </b-pagination>
    </b-overlay>
    <AddSaleModal
      ref="saleModal"
      @onSave="handleSave"
    />
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import { BCard, BTable } from 'bootstrap-vue'
import salesService from '@/services/sales'
import RootEvents from '@/constants/rootEvents'
import momentFilterMixin from '@/mixins/momentFilter'
import AddSaleModal from '../AddSaleModal.vue'
import { debounce } from 'lodash'

export default {
  components: {
    BCard,
    BTable,
    vSelect,
    AddSaleModal,
  },
  mixins: [momentFilterMixin],
  data() {
    return {
      loading: false,
      items: [],
      page: 1,
      size: 10,
      allItemsCount: 0,
      query: '',
      filteredItemsCount: 0,
      tableColumns: [
        { key: 'product', label: 'Product' },
        { key: 'car_brand', label: 'Car Brand' },
        { key: 'car_model', label: 'Model Name' },
        { key: 'chassis_number', label: 'Chassis Number' },
        { key: 'new_car', label: 'New Car' },
        { key: 'week', label: 'Sale Week' },
        { key: 'date', label: 'Sale Date' },
        { key: 'manage', label: 'Manage' },
      ],
    }
  },
  watch: {
    page: {
      handler(oldPage, newPage) {
        this.getSales()
      },
      immediate: true,
    },
    query: {
      handler(oldQuery, newQuery) {
        this.doSearch()
      },
      immediate: true,
    },
  },
  methods: {
    handleSave() {
      this.getSales()
    },
    getProductLabel(productId) {
      if (productId === '603') return 'Enter Privat'
      if (productId === '467') return 'Enter Næring'
      if (productId === '400') return 'Tryg sales'
      return ''
    },
    openModal(item) {
      this.$refs.saleModal.openModal({ item })
    },
    doSearch: debounce(function () {
      this.getSales()
    }, 500),
    async remove(item) {
      this.loading = true
      const { params } = this.$route
      if (!params?.tableName) return

      try {
        await salesService.deleteSale(params.tableName, item.uuid_key, item.version)
        this.getSales()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async getSales() {
      const { params } = this.$route
      if (!params?.tableName || !params?.contactId) {
        return
      }

      if (params?.name) {
        this.$root.$emit(RootEvents.SET_PAGE_TITLE, params.name)
      }

      this.loading = true

      try {
        const { data } = await salesService.getAllSalesBySeller(params.tableName, params.contactId, {
          page: this.page - 1,
          size: this.size,
          q: this.query,
        })

        if (!data) return

        this.items = data.pageItems
        this.allItemsCount = data.allItemsCount
        this.loading = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header {
  text-align: center;
} // modal
</style>
